interface RagDocumentsSelectorProps {
  workspaceId?: string;
  actionId?: string;
  datasourceId?: string;
}

// Used in EE
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RagDocumentsSelector = (props: RagDocumentsSelectorProps) => {
  return null;
};
